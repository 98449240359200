<template>
	<w-flex pa-3 shrink>
		<v-text-field v-model.trim="model" autofocus :label="$t('name')" maxlength="191" :rules="[rules.validName]" @keypress.enter="save()" />
	</w-flex>
</template>
<script>
export default {
	name: 'BasicFolderCreator',
	props: {
		rules: {
			type: Object,
			required: true
		},
		value: {
			type: String,
			required: false,
			default: ''
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {
		save: function () {
			this.$emit('save', this.model)
		}
	}
}
</script>
